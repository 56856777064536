export default [
  {
    key: "any",
    name: "Any",
  },
  {
    key: "misc",
    name: "Misc",
  },
  {
    key: "programming",
    name: "Programming",
  },
  {
    key: "pun",
    name: "Pun",
  },
  {
    key: "spooky",
    name: "Spooky",
  },
  {
    key: "christmas",
    name: "Christmas",
  },
] as const;
