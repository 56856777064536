export default [
  {
    key: "inspire",
    name: "Inspiring",
  },
  {
    key: "management",
    name: "Management",
  },
  {
    key: "sports",
    name: "Sports",
  },
  {
    key: "life",
    name: "Life",
  },
  {
    key: "funny",
    name: "Funny",
  },
  {
    key: "love",
    name: "Love",
  },
  {
    key: "art",
    name: "Art",
  },
  {
    key: "students",
    name: "Students",
  },
  {
    key: "bible",
    name: "Bible Verses",
  },
];
